<template>
    <div class="page">
        <div class="query-form p_b1">
            <el-input size="small" v-model="searchForm.questionnaireName" placeholder="问卷标题" maxlength="50" class="m_r1"
                      style="width: 20%" clearable></el-input>
            <el-date-picker class="m_r1" size="small"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss"
                            v-model="createTime"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="提交开始日期"
                            end-placeholder="提交结束日期" style="width: 30%">
            </el-date-picker>
            <el-button type="primary" @click="queryData(1)" size="small" icon="el-icon-search">查询</el-button>
            <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
        </div>
        <div class="bg-white">
            <el-table :data="list"
                      v-loading="loading"
                      size="small"
                      height="calc(100vh - 280px)" class="table">
                <el-table-column type="selection" width="50" fixed></el-table-column>
                <el-table-column prop="questionnaireName" label="问卷名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="device" label="提交设备" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("device",scope.row.device,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="提交时间" sortable show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="hasPermission('recycle:view')" type="text" size="mini"
                                   @click="view(scope.row)">查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <Details ref="details"></Details>
    </div>
</template>

<script>
    import Details from './recycleDetails'

    export default {
        components: {Details},
        data() {
            return {
                createTime:[],
                searchForm: {
                    createStartTime: '',
                    createEndTime: '',
                    questionnaireName: '',
                    current: 1,
                    size: 10,
                },
                list: [],
                total: 0,
                loading: false,
            }
        },
        mounted() {
            this.queryData(1)
        },
        methods: {
            // 获取数据
            queryData(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                let param = JSON.parse(JSON.stringify(this.searchForm))
                param.createStartTime = this.createTime ? this.createTime[0] : ''
                param.createEndTime = this.createTime ? this.createTime[1] : ''
                this.$axios(this.api.website.websiteuserquestionnaire, param, 'post').then((res) => {
                    if (res.status) {
                        this.list = res.data.records
                        if (this.list.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.queryData()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            // 重置
            resetSearch() {
                this.createTime = []
                this.searchForm = {
                    createStartTime: '',
                    createEndTime: '',
                    questionnaireName: '',
                    current: 1,
                    size: 10,
                },
                this.queryData(1)
            },
            // 查看
            view(row) {
                this.$refs.details.init(row)
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.queryData()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.queryData()
            },
        }
    }
</script>

<style scoped>

</style>
