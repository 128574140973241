<template>
    <el-dialog title="用户问卷明细" width="60%" :close-on-click-modal="false" :visible.sync="visible" v-if="visible">
        <div class="infoBox p_all1 m_b2">
            <div class="m_b1">提交设备：{{$dictUtils.getDictLabel("device",info.device,'')}}</div>
            <div>提交时间：{{info.createTime}}</div>
        </div>
        <div class="text_center tit">{{info.questionnaireName}}</div>
        <div class="text_center explain m_b2">{{info.questionnaireExplain}}</div>
        <div v-for="(item, index) in info.detail" :key="index" class="m_b2 p_l2">
            <div class="m_b1"><span class="mast" v-if="item.mandatory == 0">*</span>{{index+1}}/{{info.detail.length}}、{{item.websiteQuestionnaireDetailTitle}} <span v-if="item.websiteQuestionnaireDetailType == 1">（{{item.websiteQuestionnaireDetailType == 1 ? '多选' : ''}}）</span></div>
            <div v-for="(itemC, indexC) in item.selectDetail" :key="indexC">
                <span class="el-radio el-radio__input m_b2 m_l1" :class="{'is-checked': itemC.isSelect == 0}" v-if="item.websiteQuestionnaireDetailType == 0">
                <span class="el-radio__inner"></span>
                <span class="el-radio__label">{{itemC.websiteQuestionnaireSelect}}</span>
            </span>
                <span class="el-checkbox el-checkbox__input m_b2 m_l1" :class="{'is-checked': itemC.isSelect == 0}" v-if="item.websiteQuestionnaireDetailType == 1">
                <span class="el-checkbox__inner"></span>
                <span class="el-checkbox__label">{{itemC.websiteQuestionnaireSelect}}</span>
            </span>
                <div v-if="item.websiteQuestionnaireDetailType == 2">
                    <span class="el-checkbox__label m_b2 m_l1">{{itemC.websiteQuestionnaireSelectText}}</span>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible = false">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                info: {},
                details: {},
                isSelect: '0'
            }
        },
        methods: {
            init(row) {
                this.visible = true
                this.getInfo(row.id);
            },
            getInfo(id) {
                this.$axios(this.api.website.websiteuserquestionnaireGetById + '/' + id).then(res => {
                    if (res.status) {
                        this.info = res.data
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
        },
    }
</script>

<style scoped>
    .infoBox {
        background: #eeeeee;
    }
    .tit {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .explain {
        font-size: 12px;
    }

    .mast {
        color: red;
    }

</style>
